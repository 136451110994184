<template>
  <div>
    <hr>
    <p class="text-gray-100 font-medium text-normal mb-[8px] mt-[16px]">門市設定</p>
    <div class="weiby-store-setting">
      <el-table
        :data="displayData"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <el-table-column
          prop="name"
          label="門市名稱"
          fixed="left"
          align="center"
        />
        <el-table-column
          prop="identifier"
          label="門市identifier"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.identifier || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :deleteDisabled="!scope.row.identifier"
              @edit="edit(scope.row)"
              @delete="openDeleteDialog(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>

      <DeleteDialog
        v-if="modal.delete"
        title="提醒"
        content="確定要刪除 門市identifier 嗎？刪除後系統將無法進行門市核對。"
        width="610px"
        @close="modal.delete = false"
        @delete="deleteData"
      />
      <el-dialog
        title="編輯"
        :visible.sync="modal.edit"
        width="610px"
        :close-on-click-modal="false"
      >
        <el-form>
          <el-form-item label="門市identifier">
            <el-input v-model="identifier" />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button plain @click="modal.edit = false">取消</el-button>
          <el-button type="primary" @click="updateData">確認</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { defineComponent, reactive, ref, computed } from 'vue'
import { map, get } from 'lodash'
export default defineComponent({
  name: 'WeibyStoreSetting',
  components: {
    DeleteDialog,
  },
  props: {
    storeList: {
      type: Array,
      default: () => [],
    },
  },
  emit: ['refresh'],
  setup (props, { emit }) {
    const modal = reactive({
      delete: false,
      edit: false,
    })
    const selectRow = ref({
    })
    const displayData = computed(() => {
      return map(props.storeList, (item) => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phone,
          address: item.address,
          order: item.order,
          contact: item.contact,
          custom: item.custom,
          identifier: get(item, 'custom.weibyApiStoreId') || '-',
        }
      })
    })
    const openDeleteDialog = (row) => {
      selectRow.value = row
      modal.delete = true
    }
    const deleteData = () => {
      emit('delete', selectRow.value)
      modal.delete = false
    }

    const identifier = ref('')
    const edit = (row) => {
      selectRow.value = row
      identifier.value = row.identifier
      modal.edit = true
    }
    const updateData = () => {
      emit('update', { ...selectRow.value, identifier: identifier.value })
      modal.edit = false
    }
    return {
      modal,
      deleteData,
      edit,
      updateData,
      selectRow,
      displayData,
      openDeleteDialog,
      identifier,
    }
  },
})
</script>

<style lang="postcss" scoped>
.weiby-store-setting {
  @apply mb-[16px]
}
::v-deep .el-table .el-button {
  min-width: auto;
}
</style>
