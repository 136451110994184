<template>
  <BaseDialog :title="`編輯${title} 串接設定`" :btn1Loading="loading" width="610px" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item prop="enabled">
        <template #label>
          <FormItemTooltipLabel
            :label="`啟用${title}串接設定`"
            :tooltipWidth="200"
          >
            第一次開啟後，請聯繫歐巴小幫手進行會員同步，且需注意在第一次完成同步前請勿關閉此設定。
          </FormItemTooltipLabel>
        </template>
        <el-switch
          v-model="formData.enabled"
          inactive-text="關閉"
          active-text="開啟"
        />
      </el-form-item>
      <TWRoctApiForm :formData.sync="formData" :disableFields="formData.enabled" />
    </el-form>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import TWRoctApiForm from './TWRoctApiForm.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { computed, defineComponent, onMounted, set } from 'vue'
import { useShop } from '@/use/shop'
import { get, omit, find } from 'lodash'
import { CreateTWRoctClient, UpdateTWRoctClient } from '@/api/twroct'
// config
import { customModuleOptionsConfig } from '@/config/customModule'
import { usePermissions } from '@/use/permissions'
import notifyMessage from '@/config/notifyMessage'

const scope = 'twroct'

export default defineComponent({
  name: 'OpentixModuleSettingsEditModal',
  components: { BaseDialog, TWRoctApiForm, FormItemTooltipLabel },
  props: {
    configData: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enabled: false,
      apiHost: null,
      password: null,
    })
    const formRules = {
      apiHost: [noEmptyRules()],
      password: [noEmptyRules()],
    }

    const displayProviderOptions = computed(() => {
      const omitList = []
      if (!checkAction('admin.shopCustomModule.createTwroctClient')) omitList.push(scope)
      return omit(customModuleOptionsConfig, omitList)
    })

    const submitData = computed(() => {
      const data = {
        shopId: shopId.value,
        enabled: formData.enabled,
        config: {
          twroctApiHost: formData.apiHost,
          twroctApiPassword: formData.password,
        },
      }
      return data
    })

    const handleSubmit = async (data) => {
      const config = props.configData
      const openTixConfig = find(config, { type: scope })
      let apiMethod = UpdateTWRoctClient
      if (!openTixConfig) apiMethod = CreateTWRoctClient
      const [res, err] = await apiMethod(submitData.value)
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = props.configData
      const twroctConfig = find(config, { type: scope })
      if (!twroctConfig) return
      formData.enabled = get(twroctConfig, 'enabled')
      formData.apiHost = get(twroctConfig, 'config.twroctApiHost')
      formData.password = get(twroctConfig, 'config.twroctApiPassword')
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading, customModuleOptionsConfig, displayProviderOptions }
  },
})
</script>

<style lang="postcss" scoped>

</style>
