<template>
  <div class="pospal-api-form">
    <el-form-item
      v-for="(field, index) in formFields"
      :key="index"
      :label="field.label"
      :prop="field.prop"
    >
      <template v-if="field.toolTip">
        <template slot="label">
          <FormItemTooltipLabel :label="field.label" :tooltipWidth="200">
            <div v-html="field.toolTip" />
          </FormItemTooltipLabel>
        </template>
        <el-input v-model="syncFields[field.prop]" :disabled="disableFields" />
      </template>
      <el-input v-else v-model="syncFields[field.prop]" :disabled="disableFields" />
    </el-form-item>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { defineComponent, ref } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'OpentixApiForm',
  components: {
    FormItemTooltipLabel,
  },
  props: {
    formData: { type: Object, default: () => ({}) },
    disableFields: Boolean,
  },
  emits: ['update:formData'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const syncFields = useVModel(props, 'formData', emit)
    const formFields = ref([
      { label: 'X-API-ID', prop: 'opentixXApiId' },
      { label: 'X-API-Key', prop: 'opentixXApiKey' },
    ])

    return {
      syncFields,
      formFields,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
