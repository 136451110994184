<template>
  <BaseDialog :title="`編輯${title}串接模組設定`" :btn1Loading="loading" width="610px" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item prop="enabled" :label="`啟用${title}串接設定`">
        <el-switch
          v-model="formData.enabled"
          inactive-text="關閉"
          active-text="開啟"
        />
      </el-form-item>
      <DudooStoreSettingTable
        v-loading="loading"
        :storeList="storeList"
        :hideTable="!dudooConfig"
        @delete="row => updateBranch(row, 'delete')"
        @update="updateBranch"
      />
    </el-form>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
// import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
// import WeibyApiForm from '@/components/ThirdParty/Pospal/WeibyApiForm.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onMounted, set, ref } from 'vue'
import { useShop } from '@/use/shop'
import { get, find } from 'lodash'
import { CreateDudooConfig, UpdateDudooConfig, UpsertDudooShopId } from '@/api/dudoo'
// config
import { customModuleOptionsConfig } from '@/config/customModule'
import { usePermissions } from '@/use/permissions'
import { GetBranchStore } from '@/api/branchStore'
import DudooStoreSettingTable from './DudooStoreSettingTable.vue'

export default defineComponent({
  name: 'DudooModuleSettingsEditModal',
  components: { BaseDialog, DudooStoreSettingTable },
  props: {
    configData: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enabled: false,
      shopId: null,
    })
    const formRules = {
    }
    const storeList = ref([])

    const submitData = computed(() => {
      const data = { ...formData }
      return data
    })

    const dudooConfig = computed(() => find(props.configData, { type: 'dudoo' }))

    const handleSubmit = async (data) => {
      let apiMethod = UpdateDudooConfig
      if (!dudooConfig.value) apiMethod = CreateDudooConfig
      const [res, err] = await apiMethod({
        shopId: shopId.value,
        enabled: data.enabled,
        config: { },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      if (!dudooConfig.value) return
      formData.enabled = get(dudooConfig.value, 'enabled')
      formData.shopId = get(dudooConfig.value, 'shopId')
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    const getBranch = async () => {
      const [res, err] = await GetBranchStore({
        shopId: shopId.value,
      })
      if (err) window.$message.error(err)
      storeList.value = res
    }
    const refresh = async () => {
      loading.value = true
      if (checkAction('admin.branch.find')) await getBranch()
      loading.value = false
    }
    const updateBranch = async (row, action) => {
      const [, err] = await UpsertDudooShopId({
        shopId: shopId.value,
        branchId: row.id,
        dudooShopId: action === 'delete' ? null : row.dudooShopId,
      })
      if (err) window.$message.error(err)
      await refresh()
    }
    onMounted(async () => {
      syncData()
      await refresh()
    })
    return {
      formData,
      formRef,
      formRules,
      onConfirm,
      loading,
      customModuleOptionsConfig,
      storeList,
      updateBranch,
      dudooConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
