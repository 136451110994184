<template>
  <section class="section-block">
    <SectionTitle title="優惠券同步設定" hideBtn />
    <el-form v-loading="loading">
      <el-form-item label="與瑞乘 POS 上之優惠券同步">
        <el-switch
          v-model="formData.enabled"
          active-text="開啟"
          inactive-text="關閉"
          @change="onChange"
        />
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { SyncPospalCoupon, UpdatePospalConfig } from '@/api/pospal'
import notifyMessage from '@/config/notifyMessage'
import { useShop } from '@/use/shop'
import { omit, get } from 'lodash'

export default defineComponent({
  name: 'PospalSyncingSettingsBlock',
  components: { SectionTitle },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const onChange = async (toogle) => {
      if (loading.value) return
      loading.value = true

      const [, updateError] = await UpdatePospalConfig({
        shopId: shopId.value,
        config: {
          ...omit(props.configData?.config, ['shopId']),
          syncPosPalCoupon: toogle,
        },
      })
      if (updateError) {
        window.$message.error(updateError)
        loading.value = false
        return
      }

      if (!toogle) {
        loading.value = false
        window.$message.success(notifyMessage.updateSuccess)
        return
      }

      const [, err] = await SyncPospalCoupon({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        loading.value = false
        return
      }

      loading.value = false
      window.$message.success(notifyMessage.updateSuccess)
    }

    const formData = reactive({
      enabled: false,
    })

    watch(() => props.configData, (val) => {
      if (!val) return
      formData.enabled = get(val, 'config.syncPosPalCoupon')
    })

    return { onChange, formData, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
