<template>
  <div class="external-module-settings section-block">
    <SectionTitle :title="`${title}串接設定`" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="220px">
      <el-form-item v-for="field in displayData" :key="field.label" :label="field.label">
        {{ field.value }}
      </el-form-item>
    </el-form>

    <WeibyModuleSettingsEditModal
      v-if="modal.edit"
      :title="title"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import WeibyModuleSettingsEditModal from './WeibyModuleSettingsEditModal.vue'
import { get, find } from 'lodash'
import { GetCustomModule } from '@/api/customModule'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'WeibyModuleSettings',
  components: { SectionTitle, WeibyModuleSettingsEditModal },
  props: {
  },
  emits: ['refresh'],
  setup (props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const configData = ref({})
    const modal = reactive({
      edit: false,
    })
    const title = ref('微碧 POS ')
    const displayData = computed(() => {
      const config = configData.value
      return [
        { label: `啟用${title.value} 串接`, value: get(config, '[0].enabled') ? '開啟' : '關閉' },
        { label: 'api-host', value: get(config, '[0].config.weibyApiHost') || '-' },
        { label: 'x-api-key', value: get(config, '[0].config.weibyApiKey') || '-' },
        { label: '總部 Identifier', value: get(config, '[0].config.weibyApiStoreId') || '-' },
        { label: '總部 vendor-id', value: get(config, '[0].config.weibyApiVendorId') || '-' },
      ]
    })

    const getCustomModule = async () => {
      const [res, err] = await GetCustomModule({
        shopId: shopId.value,
        type: 'weiby',
      })
      if (err) throw err
      configData.value = res
    }

    const refresh = async () => {
      console.log('refresh')
      try {
        loading.value = true
        await getCustomModule()
        loading.value = false
      } catch (error) {
        window.$message.error(error)
      }
    }

    onMounted(async () => {
      refresh()
    })

    return {
      modal,
      loading,
      displayData,
      configData,
      refresh,
      title,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
