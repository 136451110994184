import { render, staticRenderFns } from "./TWRoctModuleSettings.vue?vue&type=template&id=9cc7911e&scoped=true"
import script from "./TWRoctModuleSettings.vue?vue&type=script&lang=js"
export * from "./TWRoctModuleSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cc7911e",
  null
  
)

export default component.exports