<template>
  <BaseDialog :title="`編輯 ${title}串接模組設定`" :btn1Loading="loading" width="610px" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item prop="enabled" :label="`啟用 ${title}串接設定`">
        <el-switch
          v-model="formData.enabled"
          inactive-text="關閉"
          active-text="開啟"
        />
      </el-form-item>
      <DrFootApiForm :formData.sync="formData" :disableFields="formData.enabled" />
    </el-form>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import DrFootApiForm from './DrFootApiForm.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onMounted } from 'vue'
import { useShop } from '@/use/shop'
import { get, find } from 'lodash'
import { CreateDrFootSetting, UpdateDrFootSetting } from '@/api/drFoot'
// config
import { drFootApiFieldsConfig, drFootApiFieldsRulesConfig } from '@/config/drFoot'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'DrFootModuleSettingsEditModal',
  components: { BaseDialog, DrFootApiForm },
  props: {
    configData: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enabled: false,
      ...drFootApiFieldsConfig,
    })
    const formRules = {
      ...drFootApiFieldsRulesConfig,
    }

    const submitData = computed(() => {
      const data = { ...formData }
      return data
    })

    const handleSubmit = async (data) => {
      const config = props.configData
      const drFootConfig = find(config, { type: 'drFoot' })
      let apiMethod = UpdateDrFootSetting
      if (!drFootConfig) apiMethod = CreateDrFootSetting
      console.log('apiMethod', apiMethod)
      const [res, err] = await apiMethod({
        shopId: shopId.value,
        enabled: data.enabled,
        config: { ...data, enabled: undefined },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = props.configData
      const drFootConfig = find(config, { type: 'drFoot' })
      if (!drFootConfig) return
      formData.enabled = get(drFootConfig, 'enabled')
      // formData.provider = 'pospal'
      for (const key in drFootApiFieldsConfig) {
        formData[key] = get(drFootConfig, `config.${key}`)
      }
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
