<template>
  <BaseDialog :title="`編輯${title}串接模組設定`" :btn1Loading="loading" width="610px" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item prop="enabled" :label="`啟用${title}串接設定`">
        <el-switch
          v-model="formData.enabled"
          inactive-text="關閉"
          active-text="開啟"
        />
      </el-form-item>
      <WeibyApiForm :formData.sync="formData" :disableFields="formData.enabled" />
      <WeibyStoreSettingTable
        v-loading="loading"
        :storeList="storeList"
        @delete="row => updateBranch(row, 'delete')"
        @update="updateBranch"
      />
    </el-form>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
// import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
// import WeibyApiForm from '@/components/ThirdParty/Pospal/WeibyApiForm.vue'
import WeibyApiForm from '../WeibyApiForm.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onMounted, set, ref } from 'vue'
import { useShop } from '@/use/shop'
import { get, omit, find, omitBy } from 'lodash'
import { CreateWeibyConfig, UpdateWeibyConfig } from '@/api/weiby'
// config
import { customModuleOptionsConfig } from '@/config/customModule'
import { weibyApiFieldsConfig, weibyApiFieldsRulesConfig } from '@/config/weiby'
import { usePermissions } from '@/use/permissions'
import { GetBranchStore, UpdateBranchStore } from '@/api/branchStore'
import WeibyStoreSettingTable from '../WeibyStoreSettingTable.vue'

export default defineComponent({
  name: 'WeibyModuleSettingsEditModal',
  components: { BaseDialog, WeibyApiForm, WeibyStoreSettingTable },
  props: {
    configData: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enabled: false,
      ...weibyApiFieldsConfig,
    })
    const formRules = {
      ...weibyApiFieldsRulesConfig,
    }
    const storeList = ref([])

    const submitData = computed(() => {
      const data = { ...formData }
      return data
    })

    const handleSubmit = async (data) => {
      const config = props.configData
      const weibyConfig = find(config, { type: 'weiby' })
      let apiMethod = UpdateWeibyConfig
      if (!weibyConfig) apiMethod = CreateWeibyConfig
      const [res, err] = await apiMethod({
        shopId: shopId.value,
        enabled: data.enabled,
        config: { ...data, enabled: undefined },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = props.configData
      const weibyConfig = find(config, { type: 'weiby' })
      if (!weibyConfig) return
      formData.enabled = get(weibyConfig, 'enabled')
      for (const key in weibyApiFieldsConfig) {
        formData[key] = get(weibyConfig, `config.${key}`)
      }
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    const getBranch = async () => {
      console.log('getBranch', shopId.value)
      const [res, err] = await GetBranchStore({
        shopId: shopId.value,
      })
      if (err) window.$message.error(err)
      storeList.value = res
    }
    const refresh = async () => {
      loading.value = true
      if (checkAction('admin.branch.find')) await getBranch()
      loading.value = false
    }
    const updateBranch = async (row, action) => {
      const { id, name, phone, address, order, contract, identifier } = row
      const [, err] = await UpdateBranchStore({
        shopId: shopId.value,
        storeId: id,
        name,
        phone,
        address,
        order,
        contract,
        custom: {
          weibyApiStoreId: action === 'delete' ? null : identifier,
        },
      })
      if (err) window.$message.error(err)
      await refresh()
    }
    onMounted(async () => {
      syncData()
      await refresh()
    })
    return {
      formData,
      formRef,
      formRules,
      onConfirm,
      loading,
      customModuleOptionsConfig,
      storeList,
      updateBranch,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
